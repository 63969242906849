import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PageTitle from './PageTitle';
import Container from '@mui/material/Container';
import Masonry from '@mui/lab/Masonry';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function Testimonials() {

    const windowTheme = useTheme();
    const isSmallScreen = useMediaQuery(windowTheme.breakpoints.down('md'));

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <Box>
        <PageTitle title="Testimonials" />

        <Container maxWidth="lg">

        <Masonry columns={isSmallScreen ? 1 : 2} spacing={2}>
  



          <Paper elevation={2} sx={{background: "#e6eaea", marginBottom: 2}}>
              <Box sx={{ padding:2 }}>
              <Typography sx={{marginBottom: 1}}>
                "Going through this process was daunting at first, but Lisa looked after me so well, from our first interaction right to the end.  The sessions that I had with Lisa have given me a sense of control and clarity around my wardrobe and my spending.
              </Typography>
              <Typography sx={{marginBottom: 1}}>
                Our shopping trip was a fantastic day and I am very pleased with all my purchases. I would never have purchased these items if I'd seen them on the shelf but once I put them on I was sold. I feel confident that I will now approach wardrobe shopping with a different, more positive mindset. Lisa was able to explain what clothing works best for me, and matched this perfectly with my personality.   
                </Typography>
              <Typography sx={{marginBottom: 1}}>
                Lisa is professional, prepared, and obviously very passionate about helping others - she nailed the brief!"
            </Typography>
            </Box>
            <Typography sx={{ textAlign: 'right', background: "#e6eaea", padding:1}}>
                <em>Lee, 39</em>
            </Typography>
        
        </Paper>  

        <Paper elevation={2} sx={{background: "#e6eaea", marginBottom: 2}}>
              <Box sx={{ padding:2 }}>
              <Typography sx={{marginBottom: 1}}>
              "Lisa has been such a wonderful stylist to work with. Lisa was able to easily understand my style and what it was I was hoping to achieve from our styling sessions.   
              </Typography>
              <Typography sx={{marginBottom: 1}}>
                I was so happy with the outfits Lisa found and coordinated for me and I appreciate all the styling tips and tricks along the way.  
                </Typography>
              <Typography sx={{marginBottom: 1}}>
                This talented woman has given me a new appreciation for my clothes and I feel more excited about what I wear now. Thank you Lisa!"
              </Typography>
              
            </Box>
            <Typography sx={{ textAlign: 'right', background: "#e6eaea", padding:1}}>
                <em>Tracey, 40</em>
            </Typography>
        
        </Paper>  

        <Paper elevation={2} sx={{background: "#e6eaea", marginBottom: 2}}>
              <Box sx={{ padding:2 }}>
              <Typography sx={{marginBottom: 1}}>
                "I was really nervous about the whole experience. However, right from the introductions, Lisa always took the time to understand what I needed and made me feel so comfortable.  
              </Typography>
              <Typography sx={{marginBottom: 1}}>
                Lisa didn't find me a new style or point me in the direction of the latest trends. She did so much more than that. She gave me confidence to own the style I already had but didn't know how to execute. She helped find the missing pieces in my wardrobe that would bring a look together, without starting from scratch. This was really important to me and I felt that Lisa was incredibly supportive of that process.  
               </Typography>
            <Typography sx={{marginBottom: 1}}>
                During the shopping experience, Lisa did an amazing job of preparing different outfits to try on and found a way to take me out of my comfort zone, whilst still keeping it a really fun and relaxed experience. Although my 'new outfits' might not look so 'on trend' or even very creative, they make me feel confident and less stressed about trying to find an outfit that works for me and my body. Without adding very many new items, I now have multiple outfits reusing different (new and old) pieces and feel a renewed excitement when it comes to personal style. I would highly recommend Lisa to anyone interested in a stylist appointment!"
              </Typography>
            </Box>
            <Typography sx={{ textAlign: 'right', background: "#e6eaea", padding:1}}>
                <em>Sarah, 30</em>
            </Typography>
        
        </Paper>  

        <Paper elevation={2} sx={{background: "#e6eaea", marginBottom: 7}}>
              <Box sx={{ padding:2 }}>
              <Typography sx={{marginBottom: 1}}>
              "Words can not express my gratitude for the wardrobe edit. I honestly could not be more delighted with every aspect. Before your visit, I bought clothes because I liked how they looked in the shop and crossed my fingers  they would look good on me. Unfortunately, purchasing clothes this way was so costly.  I had a lot of clothes I never wore; I ended up lost in a sea of fabric. 
              </Typography>
              <Typography sx={{marginBottom: 1}}>
              I learnt so much from our session. The initial consult helped clarify what style I wanted to achieve and identify my strengths.
                </Typography>
              <Typography sx={{marginBottom: 1}}>
              By going through the pieces I was unsure about you found the right way to wear them and how a few  of the right accessories can  totally change an outfit. For those pieces that didn't work you either  suggested mending them or explained why they weren't working so I didn't keep making the same mistakes. 
              </Typography>
              <Typography sx={{marginBottom: 1}}>
              I'm excited to have clarity in body proportions, what colours and styles work for me. 
              </Typography>
              <Typography sx={{marginBottom: 1}}>
              Lisa, I was so impressed with every aspect of the wardrobe edit. I love love love that from what I have in my wardrobe you have helped achieve looks that I previously admired"
              </Typography>
            </Box>
            <Typography sx={{ textAlign: 'right', background: "#e6eaea", padding:1}}>
                <em>Vanessa, 49</em>
            </Typography>
        
        </Paper>  

        
        </Masonry>




        </Container>

    </Box>
  );
}

export default Testimonials;
