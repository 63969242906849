import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Link from '@mui/material/Link';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

export default function Header() {

    let location = useLocation().pathname;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ backgroundColor: '#ffffff' }}
        position="fixed"
        elevation={0}
        >
        <Toolbar 
            sx={{ justifyContent: 'space-between', color: '#252627', paddingRight: 0 }}
        >
          
          <Link to="/" underline="none" component={RouterLink}><img src="./longlogo.svg" alt="Styled by Lisa J" height={48} /></Link>
          

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

            <Hidden mdDown>

            <Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/" ? 500 : 400 }} to="/" underline="none" component={RouterLink}>Home</Link>
            <Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/packages" ? 500 : 400 }} to="/packages" underline="none" component={RouterLink}>Packages</Link>
            <Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/campaigns" ? 500 : 400 }} to="/campaigns" underline="none" component={RouterLink}>Campaigns</Link>
            <Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/testimonials" ? 500 : 400 }} to="/testimonials" underline="none" component={RouterLink}>Testimonials</Link>
            {/*
            <Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/blog" ? 500 : 400 }} to="/blog" underline="none" component={RouterLink}>Blog</Link>
            */}
            <Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/contact" ? 500 : 400 }} to="/contact" underline="none" component={RouterLink}>Contact</Link>
        </Hidden>
        <Hidden mdUp>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} selected={location === "/"}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/" ? 500 : 400 }} to="/" underline="none" component={RouterLink}>Home</Link></MenuItem>    
            <MenuItem onClick={handleClose} selected={location === "/packages"}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/packages" ? 500 : 400 }} to="/packages" underline="none" component={RouterLink}>Packages</Link></MenuItem>    
            <MenuItem onClick={handleClose}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/campaigns" ? 500 : 400 }} to="/campaigns" underline="none" component={RouterLink}>Campaigns</Link></MenuItem>
            <MenuItem onClick={handleClose} selected={location === "/testimonials"}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/testimonials" ? 500 : 400 }} to="/testimonials" underline="none" component={RouterLink}>Testimonials</Link></MenuItem>
            {/*
            <MenuItem onClick={handleClose}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/blog" ? 500 : 400 }} to="/blog" underline="none" component={RouterLink}>Blog</Link></MenuItem>
            */}
            <MenuItem onClick={handleClose} selected={location === "/contact"}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/contact" ? 500 : 400 }} to="/contact" underline="none" component={RouterLink}>Contact</Link></MenuItem>
            <MenuItem onClick={handleClose} selected={location === "/privacy"}><Link variant="body2" sx={{marginRight: 2, fontWeight: location === "/privacy" ? 500 : 400 }} to="/privacy" underline="none" component={RouterLink}>Privacy</Link></MenuItem>
            </Menu>

          </Hidden>



        </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}