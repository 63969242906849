import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type Props = {
    children: JSX.Element,
  };

const MarginBox = ({ children }: Props) => {

    const windowTheme = useTheme();
    const isSmallScreen = useMediaQuery(windowTheme.breakpoints.down('sm'));

  return (
    <Box sx={{marginTop: isSmallScreen ? '56px' : '64px'}}>
        {children}
    </Box>
  );
}

export default MarginBox;
