import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import PageTitle from './PageTitle';
import Hidden from '@mui/material/Hidden';
import { Email } from '@mui/icons-material';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';

function Contact() {

    const windowTheme = useTheme();
    const isSmallScreen = useMediaQuery(windowTheme.breakpoints.down('md'));

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <Box>

        <PageTitle title="Contact" />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', background: '#e6eaea'}}>
        <Hidden mdDown>
            <Box>
                <img src="./lisa2.jpg" alt="logo" style={{height:`calc(100vh - ${isSmallScreen ? 134 : 142}px)`}} />
            </Box>
        </Hidden>
        <Box sx={{textAlign: 'left', width: '100%', height:`calc(100vh - ${isSmallScreen ? 134 : 142}px)`, marginLeft: 4, marginRight: 4,  display: 'flex', alignItems: isSmallScreen ? 'unset' : 'center', justifyContent: 'center'}}>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: 'sm' }}>
            <Paper elevation={2} sx={{padding: 6}}>
            <Box sx={{textAlign: 'center', marginBottom: 4}}>
                <img src="./boxlogo.svg" alt="logo" style={{maxWidth: isSmallScreen ? '90%' : 360}} />
            </Box>
            <Typography variant="body1">If you are ready to invest in yourself - Lisa would love to hear from you.</Typography>
            <Box sx={{ display: 'flex', marginTop: 2 }}>
                <Email />
                <Typography variant="body1" sx={{ marginLeft: 1 }}><Link href="mailto:lisa@styledbylisaj.com.au">lisa@styledbylisaj.com.au</Link></Typography>
            </Box>
            </Paper>
            </Box>
        </Box>
        </Box>
    </Box>
  );
}

export default Contact;
