import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { EmailOutlined, FacebookOutlined, Instagram } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Hidden from '@mui/material/Hidden';

export default function Footer() {
  const [value, setValue] = React.useState(0);

  return (
    <Box sx={{ position: 'fixed', bottom: 0, width: "100%", background: "#252627", display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography sx={{marginLeft: 2, fontSize: '0.7em' ,color: "#ffffff"}}>
            &copy;&nbsp;Styled by Lisa J, 2022
        </Typography>
      
      <Hidden mdDown>
        <Link variant="body2" sx={{fontSize: '0.7em' ,color: "#ffffff"}} to="/privacy" underline="none" component={RouterLink}>Privacy</Link>
    </Hidden>

<Box sx={{marginRight: 1, display: 'flex', alignItems: 'center' }}>
    
        <Link component="a" href="https://www.facebook.com/profile.php?id=100061515244590" target="_blank" aria-label="Facebook">
      <IconButton aria-label="Facebook">
          <FacebookOutlined sx={{color:"#ffffff"}} />
      </IconButton>
      </Link>
      <Link component="a" href="https://www.instagram.com/styledbylisaj/" target="_blank" aria-label="Instagram">
      <IconButton aria-label="Instagram">
          <Instagram sx={{color:"#ffffff"}} />
      </IconButton>
      </Link>
      
      <Link variant="body2" sx={{fontSize: '0.7em' ,color: "#ffffff"}} to="/contact" underline="none" component={RouterLink} aria-label="Contact">
          <IconButton aria-label="Contact">
            <EmailOutlined sx={{color:"#ffffff"}} />
        </IconButton>
      </Link>
      
      </Box>
    </Box>
  );
}