import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PageTitle from './PageTitle';
import { Container } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { CampaignConfig } from './types/CampaignConfig';
import Campaign from './Campaign';

function Campaigns() {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      const items:CampaignConfig[] = [
        { name: "2021: Vinnies Catalogue", role: "Campaign Stylist & Model", imageLink: 'Vinnies1.png'  },
        { name: "2021: Vinnies Catalogue", role: "Campaign Stylist & Model", imageLink: 'Vinnies2.png'  },
        { name: "2021: Vinnies Catalogue", role: "Campaign Stylist & Model", imageLink: 'Vinnies3.png'  },
        
        { name: "2022: \"Reborn the Runway\" (As We Are Agency)", role: "Head Dresser & Backstage Coordinator", imageLink: 'ReborntheRunway.png', link: 'https://www.asweareagency.com.au/' },

        { name: "2022: Stafford Salvos Inclusive Fashion Showcase", role: "Assistant Stylist & Model", imageLink: 'Salvos1.jpg'  },
        { name: "2022: Stafford Salvos Inclusive Fashion Showcase", role: "Assistant Stylist & Model", imageLink: 'Salvos2.jpg'  },

        { name: "2022: Various Brisbane fashion shows", role: "Back of House Assistant", imageLink: 'VariousBrisbane.jpg'  },
      ]

  return (
    <Box>
        <PageTitle title="Campaigns" />

        <Container maxWidth="md">

            <Typography variant="body1" sx={{mb: 3}}>
            Lisa's work in the editorial space includes styling and modelling for promotional campaigns as well as back of house assistance and runway styling. 
            </Typography>
        <Carousel
        interval={6000}
        >
            {
                items.map( (item, i) => (<Campaign key={i} config={item} />) )
            }
        </Carousel>

        
        </Container>

    </Box>
  );
}

export default Campaigns;
