import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PageTitle from './PageTitle';

function Blog() {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

  return (
    <Box>
        <PageTitle title="Blog" />
    </Box>
  );
}

export default Blog;
