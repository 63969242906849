import React, { ReactNode } from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import SvgIcon from '@mui/material/SvgIcon';

type Props = {
    title: string,
    blurb: string,
    inclusions: string[],
    price?: number,
    totalEffort: string,
    image: string,
    svg: ReactNode,
  };

const Package = ({ title, blurb, inclusions, price, totalEffort, image, svg }: Props) => {

    const windowTheme = useTheme();
    const isSmallScreen = useMediaQuery(windowTheme.breakpoints.down('md'));

return (

        <Card sx={{ maxWidth: isSmallScreen ? '100%' : '80%' }}>
            
            <CardHeader
                title={
                    <Box sx={{display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body1" sx={{fontSize: '1.125rem'}}>{title}</Typography>
                        <div>{`$${price}`}</div>
                    </Box>
                }
                sx={{background: '#e6eaea', color: "#252627"}}
                avatar={<SvgIcon>{svg}</SvgIcon>}
            />
            <CardMedia
                component="img"
                alt="Hangers and clothes"
                height="140"
                image={`./${image}`}
            />
            <CardContent>
            
            <Typography gutterBottom variant="body2" component="div">
                <div dangerouslySetInnerHTML={{__html: `<span>${blurb}</span>`}}></div>    
            </Typography>
                
                <Typography gutterBottom variant="body2" component="div" sx={{marginBottom: 1, fontWeight: 500, marginTop: 2, textAlign: 'center'}}>
                Includes
                </Typography>

                <List sx={{ marginTop: 0, listStyle: 'disc' }}>
                    {
                        inclusions.map((inclusion, index) => {
                            return <ListItem key={index} sx={{marginLeft: '16px', paddingLeft: '8px', display: 'list-item', paddingBottom: 0 }}>{inclusion}</ListItem>
                        })
                    }
                </List>
                
                <Typography variant="body2" color="text.secondary" sx={{marginBottom: 0, marginTop: 3, textAlign: 'right'}}>
                    { totalEffort }
                </Typography>
            </CardContent>
            
            <CardActions>
                
            <Link variant="body2" to="/contact" underline="none" component={RouterLink}>
                <Button size="small">Contact Lisa</Button>
                </Link>
            
            </CardActions>
            </Card>
  );
}

export default Package;
