import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Blog from './Blog';
import Campaigns from './Campaigns';
import Packages from './Packages';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Privacy from './Privacy';
import Testimonials from './Testimonials';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import MarginBox from './MarginBox';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
      },
      palette: {
        primary: {
            main: '#252627',
        },
        secondary: {
            main: '#e6eaea',
        }
      },
      shape: {
        borderRadius: 0,
      }, 
      components: {
        MuiCardHeader: {
            styleOverrides: {
                title: {
                    fontSize: '1.125rem',
                    fontWeight: 500
                  }
            } 
        }
      }

  });

  declare module '@mui/material/styles' {
    interface Theme {
      status: {
        danger: string;
      };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
      status?: {
        danger?: string;
      };
    }
  }

root.render(
  <React.StrictMode>
      
      <ThemeProvider theme={theme}>
      
      <BrowserRouter>
      <Box>
        <Header  />
        <MarginBox>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="blog" element={<Blog />} />
            <Route path="campaigns" element={<Campaigns />} />
            <Route path="contact" element={<Contact />} />
            <Route path="packages" element={<Packages />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="testimonials" element={<Testimonials />} />
            <Route path="*" element={<App />} />
        </Routes>
        </MarginBox>
        </Box>
        <Footer />
        
        </BrowserRouter>
        
      </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
