import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

type Props = {
    title: string,
    colour?: string,
  };

const PageTitle = ({ title, colour }: Props) => {

return (
    <Box sx={{margin: 2, marginTop: 2, justifyContent: 'center', display: 'flex'}}>
    <Typography sx={{marginTop: '24px', marginBottom: '20px', width: "100%", maxWidth: '320px', textAlign: "center", borderBottom: "1px solid #bac6c6", lineHeight: "0.1em" }}>
        <Box component="span" sx={{background: colour ? colour : "#fff", padding: "0 10px" }}>
            {title}
        </Box>
    </Typography>
    </Box>
  );
}

export default PageTitle;
