import React from 'react';
import logo from './logo.svg';
import Header from './Header';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PageTitle from './PageTitle';
import { Container, IconButton, ImageListItem, ImageListItemBar, Paper } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { CampaignConfig } from './types/CampaignConfig';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

interface ownProps {
    config: CampaignConfig,
}

function Campaign(props: ownProps) {

    React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

     
  return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <ImageListItem sx={{maxHeight: '60vh', minHeight: '60vh' }}>
          <img
            src={props.config.imageLink}
            alt={props.config.name}
            style={{maxHeight: '60vh', minHeight: '60vh'}}
          />
          <ImageListItemBar          
            title={props.config.name}
            subtitle={props.config.role}
            actionIcon={props.config.link ?
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.87)', mr: 1.5 }}
                aria-label={`More info about ${props.config.name}`}
                component="a"
                href={props.config.link}
                target="_blank"
              >
                <OpenInNewIcon />
              </IconButton>
              : null
            }
          />
        </ImageListItem>
        </Box>
    
  );
}

export default Campaign;
